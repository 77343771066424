/* ORIGINAL NON-DEFERRABLE CODE FROM MEDSCAPE-COMMON */

var locale = "us"; //overwritten on language-edition templates - any code run inline on main JS files will have 'us' always, after doc-ready / lower on page, works as expected...
var isMobile = /blackberry|blazer|iphone|ipod|android|webos|nokia|opera mini|palm|samsung|sonyericsson|vodafone|windows ce|iemobile/i.test(navigator.userAgent.toLowerCase());

var breakpoint;
if (window.innerWidth <= 767) {
    breakpoint=1;
}else if(window.innerWidth >= 768 && window.innerWidth <= 1023 ) {
    breakpoint=2;
} else if(window.innerWidth >= 1024 && window.innerWidth <= 1279) {
    breakpoint=3;
} else {
    breakpoint=4;
}

/*** BreakPoint function ***/
function viewPortSetting(){
    var lastBreakpoint = breakpoint;
    if (window.innerWidth <= 767) {
        breakpoint=1;
    }else if(window.innerWidth >= 768 && window.innerWidth <= 1023 ) {
        breakpoint=2;
    } else if(window.innerWidth >= 1024 && window.innerWidth <= 1279) {
        breakpoint=3;
    } else {
        breakpoint=4;
    }
    if(lastBreakpoint != breakpoint){
        $(document).trigger("viewportChange",breakpoint);
    }
}

$(window).resize(function() {
    viewPortSetting();
});

function searchView() {
    $("#searchlayer").toggleClass("showSearch");
    $("body").toggleClass("modal-open");
}

/* ORIGINAL NON-DEFERRABLE CODE FROM MEDSCAPE-COMMON */


/* MOVED HERE FROM MEDSCAPE-LIBRARY */

/* Communications Platform Headlines */
var cpHlArr = new Array();

function cpHlPush(hlImpr) {
    cpHlArr.push(hlImpr);
}

/* Communications Platform */
var cpAdCallArr = new Array();
var cpAdExpArr = new Array();

function cpArticleLoad(appname) {
    var cpArticle = {};
    var testPagination;
    segVarPop(cpArticle);
    cpArticle.appname = appname;
    cpArticle.activityName = "view";
    if (cpArticle.appname === "viewarticle") {
        testPagination = window.location.pathname.match(/\/viewarticle\/\d+_\d+/);
        if (testPagination !== null) {
            cpArticle.pageN = window.location.pathname.match(/\/viewarticle\/\d+_\d+/)[0].split('_')[1];
        } else {
            cpArticle.pageN = 1;
        }
    }
	else if (cpArticle.appname === "drug" && typeof CURRENT_DRUG_ID !== 'undefined') {
		cpArticle.activityId = Number(CURRENT_DRUG_ID.trim());
	}
    postCp(cpArticle);
}

function segVarPop(obj) {
    if (typeof DFPTargetKeys != 'undefined' && typeof DFPTargetKeys.pageSegVars != 'undefined') {
        if (DFPTargetKeys.pageSegVars.art != "0") {
            obj.activityId = Number(DFPTargetKeys.pageSegVars.art);
        }
        if (DFPTargetKeys.pageSegVars.ssp != "0") {
            obj.leadSpec = Number(DFPTargetKeys.pageSegVars.ssp);
        }
        if (DFPTargetKeys.pageSegVars.as != "0") {
            if (typeof DFPTargetKeys.pageSegVars.as === 'string') {
                obj.allSpecialties = $.map(DFPTargetKeys.pageSegVars.as.split(','), function (obj) {
                    return Number(obj);
                });
            } else {
                obj.allSpecialties = DFPTargetKeys.pageSegVars.as;
            }
        }
        if (DFPTargetKeys.pageSegVars.scg != "0") {
            obj.leadConcept = Number(DFPTargetKeys.pageSegVars.scg);
        }
        if (DFPTargetKeys.pageSegVars.ac != "0") {
            if (typeof DFPTargetKeys.pageSegVars.ac === 'string') {
                obj.allConcepts = $.map(DFPTargetKeys.pageSegVars.ac.split(','), function (obj) {
                    return Number(obj);
                });
            } else {
                obj.allConcepts = DFPTargetKeys.pageSegVars.ac;
            }
        }
        if (DFPTargetKeys.pageSegVars.cg != "0") {
            obj.contentGroup = Number(DFPTargetKeys.pageSegVars.cg);
        }
        if (DFPTargetKeys.exclusionCategories.length > 0) {
            obj.blockCode = DFPTargetKeys.exclusionCategories;
        }
    }
}

//Empty object to bypass errors when ad blocker disables profads or webmd.
if (typeof profads === "undefined") {
    var profads = {
        ads2: {
            GenPVID: function () {
                return null
            },
            init: function () {
            },
            KillSwitch: function () {
                return true;
            },
            GenPVID:function () {
            }
        }
    };
}
if (typeof webmd === "undefined") {
    var webmd = {
        ads2: {
            defineAd: function () {
                return null
            },
            refresh: function () {
                return null
            }
        }
    };
}

/* Tooltip functions with Cross-domain: 'allDomain' values = 'all', 'com', or boolean, 'closeOnclick' = boolean, 'priority' = number (1 rank highest, 0 ignored)  */

var toolTipArr = [];

function initTooltip(cookieName, className, closeOnclick, allDomain, priority) {
    if (document.cookie.indexOf(cookieName + '=') == -1) {
		toolTipArr.push([cookieName, className, closeOnclick, allDomain, priority]);
    }
}

// Invalid Email Widget tracking (Adobe Launch code not ready in time for initial pageview, so unfortunately need to mirror logic for tracking call)
function isValidFlag() {
	if (typeof PageMetadata.extraObject != 'undefined' && PageMetadata.extraObject.isValidEmail == 1 && Number(PageMetadata.extraObject.accstat) == 1 && typeof PageMetadata.authVar != 'undefined' && Number(PageMetadata.authVar.authLevel) == 2 && (PageMetadata.extraObject.mpowd == null || PageMetadata.extraObject.mpowd == true || window.location.search.indexOf('confirmRiskyUpdate=true') != -1) && (window.location.href.indexOf('viewarticle') != -1 || window.location.href.indexOf('artikelansicht') != -1 || window.location.href.indexOf('voirarticle') != -1 || window.location.href.indexOf('verarticulo') != -1 || window.location.href.indexOf('verartigo') != -1)) {
        return true;
    } else {
         return false;
    }      
}
function loadInvalidEmailWidg() {
    if(isValidFlag()) {
        document.getElementById("column-left").setAttribute("data-metric-dynamic", "poe");
		document.addEventListener('afterPageView', function() { document.getElementById("column-left").removeAttribute("data-metric-dynamic"); });
    }
}
document.addEventListener('beaconInitialized', loadInvalidEmailWidg);

// 'Medscape Beacon' tracking append (called from footer JSP)
function addMedBeacon() {
	try {
        var actIdStr = '';
        if (typeof PageMetadata.userSegVars != 'undefined' && typeof  PageMetadata.userSegVars.actid != 'undefined') {
            actIdStr = '&actid=' + PageMetadata.userSegVars.actid;
        }
        var npiStr = '';
        if (typeof PageMetadata.userSegVars != 'undefined' && typeof PageMetadata.userSegVars.dt == 'string' && PageMetadata.userSegVars.dt != '') {
            npiStr = '&dt=' + PageMetadata.userSegVars.dt;
        }
        var authTokenStr = '';
        if (typeof PageMetadata.authVar != 'undefined' && typeof PageMetadata.authVar.tokenValue != 'undefined') {
            authTokenStr = '&authtoken=' +  PageMetadata.authVar.tokenValue;
        }
        $('body').append('<iframe width="0" height="0" style="display:none;" src="//img.webmd.com/medscape/medscapebeacon.html?domain=webmd.com&guid=' + $.trim(s_registered_user_id) + '&tl=[' + $.trim(userCampaign).replace(/^\[/,'').replace(/\]$/,'') + ']&c=' + DFPTargetKeys.userSegVars.ct + '&p=' + DFPTargetKeys.userSegVars.pf + '&o=' + DFPTargetKeys.userSegVars.occ + '&tac=[' + $.trim(userTactics) + ']&st=' + DFPTargetKeys.userSegVars.st + '&ps=' + DFPTargetKeys.userSegVars.usp + actIdStr + npiStr + authTokenStr + '"></iframe>');
		
	}
	catch(e) {
		console.log('error appending medscapebeacon iframe: ');
		console.log(e);
	}
}

// carousel rotator code
function carousel(rotateID, CarW, DisVal, TruncVal) {
    if(typeof DisVal =="undefined"){DisVal=4}
    var CarWp = (CarW * 1) + 20;
    var rotatorTotal = $('ul#' + rotateID + ' li').size();
    $('#' + rotateID).css({
        'width': rotatorTotal * CarW + 'px'
    });
    rotatorTotal = Math.ceil(rotatorTotal / DisVal) * 1;
    var thisli = 1;
    if (rotatorTotal <= thisli) {
        $('#rotate_left').hide();
        $('#rotate_right').hide();
    }
    for (y = 0; y < rotatorTotal; y++) {
        $('#rotator_image').append('<span id="rotatorli' + (y + 1) + '" class="totalli"></span>');
    }
    $('#rotatorli1').addClass('thisli');

    $('#rotate_left').click(function() {
        if ($(this).hasClass('rotate_btn-dis')) {
            return
        } else {
            thisli = thisli - 1;
            $('#rotator_thisli').html(thisli);
            $('.thisli').removeClass('thisli');
            $('#rotatorli' + thisli).addClass('thisli');
            $('ul#' + rotateID).animate({
                "left": "+=" + CarWp + "px"
            }, "medium");
            if (thisli != rotatorTotal) {
                $('#rotate_right').removeClass('rotate_btn-dis');
            }
            if (thisli == '1') {
                $('#rotate_left').addClass('rotate_btn-dis');
            } else {
                $('#rotate_left').removeClass('rotate_btn-dis');
            }
        }
    });

    $('#rotate_right').click(function() {
        if ($(this).hasClass('rotate_btn-dis')) {
            return
        } else {
            thisli = thisli + 1;
            $('#rotator_thisli').html(thisli);
            $('.thisli').removeClass('thisli');
            $('#rotatorli' + thisli).addClass('thisli');

            $('ul#' + rotateID).animate({
                "left": "-=" + CarWp + "px"
            }, "medium");
            if (thisli != 1) {
                $('#rotate_left').removeClass('rotate_btn-dis');
            }
            if (thisli == rotatorTotal) {
                $('#rotate_right').addClass('rotate_btn-dis');
            } else {
                $('#rotate_right').removeClass('rotate_btn-dis');
            }
        }
    });

    /* Truncate the titles */
    if (TruncVal !== undefined) {
        $("ul#" + rotateID + " li a.title").each(function() {
            if ($(this).text().length > (TruncVal - 1) && $(this).text().indexOf(" ", TruncVal) != -1) {
                var nextsp = $(this).text().indexOf(" ", TruncVal);
                var tempcartitle = $(this).text().substring(0, nextsp);
                $(this).text(tempcartitle + ' ...');
            }
        });
    }
}

/* MOVED HERE FROM MEDSCAPE-LIBRARY */

/* MOVED HERE FROM MEDSCAPE-CORE */
function GenPVID() {
    // getting the date, getting the seconds from epoch
    var timestamp = new Date().getTime() / 1000;
    //Rounding the decimal off
    var epochSec = Math.round(timestamp).toString();
    // making an 8 digit random number
    var randomNumber = Math.random().toString().substr(2, 8);
    // set the global variable to the 19 digit page view id
    var tempPVIDreturn = epochSec + randomNumber;

    s_pageview_id = tempPVIDreturn;

    $(document).trigger("pvidReset");
}
/* MOVED HERE FROM MEDSCAPE-CORE  */

/* COPIED HERE FROM MEDSCAPE-SITE.JS */

var getDomain = function(profreg){
    var _domain ="";
    if (window.location.host.split('.').length == 4){
        _domain = window.location.host.split('.')[1] + ".";
    }
    if (window.location.host.split('.').length == 5){
        _domain = window.location.host.split('.')[1] + "."+ window.location.host.split('.')[2] + ".";
    }
    if(profreg=="profreg"||profreg=="login"){
        _domain = _domain.replace('staging.','').replace('proddev.','');
    }
    return _domain;
}
/* COPIED HERE FROM MEDSCAPE-SITE.JS */

// Keep stub function to prevent undefined errors on legacy content
if (typeof swfobject == 'undefined') {
	var swfobject = {
		'embedSWF': function() {
			
		}
	};
}

// Get Auth Level from F.E. vars (with fallback)
function getAuthLevel() {
	var authLevel = 0;
	if (typeof PageMetadata !== 'undefined' && typeof PageMetadata.authVar !== 'undefined') {
		authLevel = Number(PageMetadata.authVar.authLevel);
	} else if (typeof authlevel == 'number') {
		authLevel = authlevel;
	}
	else if (typeof s_auth_channel_id == 'string') {	
		if (typeof s_auth_channel_id.split('-')[1] !== 'undefined') {
			authLevel = Number(s_auth_channel_id.split('-')[1]);
		}
		else {
			if (s_auth_channel_id == '100') {
				authLevel = 0;
			}
			else if (s_auth_channel_id == '103' || s_auth_channel_id == '104' || s_auth_channel_id == '105' || s_auth_channel_id == '107' || s_auth_channel_id == '108' || s_auth_channel_id == '110') {
				authLevel = 2;
			}
			else {
				authLevel = 1;
			}
		}	
	}
	
	return authLevel;
}

/* PageMetadata logic for Edge Cache and Static Metadata */
if (typeof PageMetadata != 'undefined' && typeof PageMetadata.authVar  != 'undefined' && typeof PageMetadata.authVar.authenticationChannel !== 'undefined') {
	var s_auth_channel_id = PageMetadata.authVar.authenticationChannel;
	if (typeof PageMetadata.authVar.authLevel !== 'undefined') {
		s_auth_channel_id = PageMetadata.authVar.authenticationChannel + '-' + PageMetadata.authVar.authLevel;
	}
}
if (typeof PageMetadata != 'undefined' && typeof PageMetadata.userSegVars != 'undefined' && PageMetadata.userSegVars.gd && PageMetadata.userSegVars.gd !== null && PageMetadata.userSegVars.gd !== '' && PageMetadata.userSegVars.gd !== '0') { 
    if (typeof PageMetadata.userSegVars.gd === 'number') {
        PageMetadata.userSegVars.gd = PageMetadata.userSegVars.gd.toString();
    }
    var s_registered_user_id = PageMetadata.userSegVars.gd;
}

/* FPF / FAF */
var fpfMeta = {
	isFAF: false,
	isFAFAnon: false,
	isFPF: false,
	fafLimit: 0,
	fafSeen: 0,
	fafRef: false	
};

var fpfSuppressAds = ['141','420','520','1122','1420','1520'];

function fpfBlockAds() {
	fpfSuppressAds.forEach(function(pos) {
		window.ads2_ignore[pos] = "true";
		if (typeof webmd.ads2.ads['ads-pos-' + pos] != 'undefined') {
			delete webmd.ads2.ads['ads-pos-' + pos];
		}
	});
}

function initFPF() {
	if (typeof isFPFEligible != 'undefined') { // FPF/FAF is applicable for current template
		
		// Sync up Edge-Cache version of isFPFEligible
		if (getAuthLevel() > 0) {
			isFPFEligible = null;
		}
		if (isFPFEligible == false) {
			isFPFEligible = null;
		}
		if (isFPFEligible == true && typeof isFPFConceptEligible != 'undefined' && !isFPFConceptEligible) {
			isFPFEligible = null;
		}
		if (typeof isFPFGated != 'undefined') {
			if (isFPFGated == false) {
				isFPFEligible = null;
			}
		}
		
		// Override default value from optional global if present
		if (typeof window.fafLimit == 'number') {
			fpfMeta['fafLimit'] = window.fafLimit;
		}
		
		// Get cookie value if not first-run default
		if (document.cookie.match(/first_article_visited=(\d+)/) != null) {
			fpfMeta['fafSeen'] = Number(document.cookie.match(/first_article_visited=(\d+)/)[1])
		}
		var fafRefCookie = document.cookie.match(/fafReferrer=([^;]+)/);
		
		if (getAuthLevel() == 0) {
			if (window.location.search.indexOf('fpf=1') != -1) {
                fpfMeta['isFPF'] = true;
            } else if (window.location.search.indexOf('faf=1') != -1) { // FAF via query param, the original FAF use case
				if ((typeof isFPFGated == 'boolean' && !isFPFGated) || (typeof isFPFConceptEligible == 'boolean' && !isFPFConceptEligible)) {
				    fpfMeta['isFAF'] = false;
				} else {
					isFPFEligible = null;
                    fpfMeta['isFAF'] = true;
					document.cookie = 'fafReferrer= ' + 'faf|' + encodeURIComponent(window.location.pathname.split('_')[0].split('-')[0]) + '; path=/; domain=medscape.com;';
                }
			} else if (fafRefCookie != null && location.pathname.indexOf(decodeURIComponent(fafRefCookie[1].split('|')[1])) != -1) {   // FAF or 'FAF Anon' via Referrer
				fpfMeta['fafRef'] = true;
				if (fafRefCookie[1].indexOf('fafanon') != -1) {
					fpfMeta['isFAFAnon'] = true;
				} else if (fafRefCookie[1].indexOf('faf') != -1) {
					isFPFEligible = null;
					fpfMeta['isFAF'] = true;	
				}
			} else if (isFPFEligible == true && fpfMeta['fafSeen'] < fpfMeta['fafLimit']) {  // One free article allowed before FPF gating aka 'FAF Anon'
				fpfMeta['isFAFAnon'] = true;
				document.cookie = 'fafReferrer= ' + 'fafanon|' + encodeURIComponent(window.location.pathname.split('_')[0].split('-')[0]) + '; path=/; domain=medscape.com;';
			} else if (isFPFEligible == true && (fpfMeta['fafSeen'] >= fpfMeta['fafLimit'] || fpfMeta['fafLimit'] == 0)) { // FPF blocking layer
				fpfMeta['isFPF'] = true;
			}
		}
		
		// Query-params from Membership-team standalone module autotrigger FPF blocking layer
		if (window.location.search.indexOf('isSocialFail=true') != -1 || window.location.search.indexOf('isFpfNewsletter=true') != -1 || window.location.search.indexOf('icd=login_error_') != -1 || window.location.search.indexOf('form=pushConfirm') != -1 || window.location.search.indexOf('form=forgotConfirm') != -1)  {
			fpfMeta['isFAF'] = false;
			fpfMeta['isFAFAnon'] = false;
			isFPFEligible = true;
			fpfMeta['isFPF'] = true;
		}

		// Block Ads when FPF is blocking page
		if (fpfMeta['isFPF']) {
			// Block Ads when FPF is blocking page
			if (typeof window.libLoadStatus == 'object' && window.libLoadStatus.libs.ads) {
				fpfBlockAds();
			} else {
				document.addEventListener('LibReady', function(e) {
					if (e.detail = 'ads') {
						fpfBlockAds();
					}				
				});
			}
		}

		// FPF/FAF tracking	
		if (typeof window.s_context == 'undefined') {
			window.s_context = {};
		}
		if (fpfMeta['isFAF']) {
			window.s_context['wb.modimp'] = 'faf';
		} else if (fpfMeta['isFAFAnon']) {
			window.s_context['wb.modimp'] = 'faf-anon';
		} else if (fpfMeta['isFPF']) {
			window.s_context['wb.modimp'] = 'fpf';
		}
		
	}
}

if (typeof isFPFEligible != 'undefined') {
	document.addEventListener('LibReady', function(e) {
		if (e.detail = 'ads') {
			fpfSuppressAds.forEach(function(pos) {
				if (typeof window.ads2_ignore[pos] != 'undefined') {
					delete window.ads2_ignore[pos];	
				}
			});
		}
	})
}
	
if (typeof window.libLoadStatus == 'object' && window.libLoadStatus.complete) {
	initFPF();
} else if (typeof window.libLoadStatus == 'object') {
	document.addEventListener('userIDComplete', initFPF);
} else {
	initFPF();	
}
/* /FPF / FAF */

// PPE-473750
document.addEventListener('beforePageView', function() {
	if (window.location.href.match(/(viewarticle\/\d+_|#vp_?|#|#page=|&page=|\?page=|&pagenumber=|\?pagenumber=)(\d+)/) !== null) {
		wmdSetContext('wb.pagination', window.location.href.match(/(viewarticle\/\d+_|#vp_?|#|#page=|&page=|\?page=|&pagenumber=|\?pagenumber=)(\d+)/)[2]);
	}
});
(function(){
     var medias = [{
            host: "webmd-vh.akamaihd.net",
            endpoint: "/i/delivery",
            type: "application/x-mpegURL",
            file: ".csmil/master.m3u8",
        },
        {
            host: "webmd-a.akamaihd.net",
            endpoint: "/delivery",
            type: "video/mp4",
            file: "750k.mp4",
        },
        {
            host: "webmd-a.akamaihd.net",
            endpoint: "/delivery",
            type: "audio/mpeg",
            file: "a96k.mp4",
        },
    ];
    window.medscape = window.medscape || {};
    window.medscape.configs = window.medscape.configs || {};
    window.medscape.configs.medias = medias;
})();